import(/* webpackMode: "eager" */ "/opt/build/repo/app/globals.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/opt/build/repo/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../public/fonts/RoobertLight.ttf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../public/fonts/RoobertRegular.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../public/fonts/RoobertMedium.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../public/fonts/RoobertSemiBold.ttf\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../public/fonts/RoobertBold.ttf\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--roobert\"}],\"variableName\":\"roobert\"}");
